import React, {memo, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import {IDataGridMadel} from "../model";
import Head from "./head";
import Body from "./body";
import Scrollbars from "react-custom-scrollbars";
import {useSelector} from "react-redux";

export default memo((
  {
    model,
    data,
    sortable,
    onTasks,
    rowControls,
    preview,
    pagination
  }
    : {
    model: IDataGridMadel;
    data: any[];
    sortable: any;
    onTasks: (tasks: any[]) => void;
    rowControls?: ReactNode;
    preview?: boolean;
    pagination: any;
  }
) => {
  const elScroll = useRef<any>(null);
  const elTable: any = useRef<any>(null);
  const [scroll, setScroll] = useState({x: 0, y: 0, '-x': 0});
  const {isRTL} = useSelector(state => (state as any).language);

  const onResize = useCallback(() => {
    if (elScroll && elScroll.current) {
      const scroll_ = elScroll.current;
      const x_ = scroll_.getScrollLeft();
      const x__ = scroll_.getClientWidth() - scroll_.getScrollWidth() + x_;
      setScroll({
        x: isRTL ? x__ : x_,
        y: scroll_.getScrollTop(),
        '-x': isRTL ? x_ : x__
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elScroll, elTable, setScroll, isRTL]);

  useEffect(() => {
    elScroll.current.scrollTop(0);
    if (isRTL) elScroll.current.scrollToRight(0); else elScroll.current.scrollLeft(0);
    onResize();
  }, [onResize, data, model, preview, isRTL]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Scrollbars
    ref={elScroll}
    className="r-scroll-bar data-grid-scroll"
    autoHide={false}
    autoHideTimeout={0}
    autoHideDuration={0}
    onScroll={onResize}
  >
    <table
      className={`${scroll.x ? 'is-scroll-x' : ''}${scroll.y ? ' is-scroll-y' : ''}${scroll['-x'] ? ' is-scroll--x' : ''}`}
    >
      <Head
        model={model}
        sortable={sortable}
        scroll={scroll}
        onTasks={onTasks}
        rowControls={rowControls}
        preview={preview}
      />
      <Body
        model={model}
        data={data}
        scroll={scroll}
        onTasks={onTasks}
        rowControls={rowControls}
        preview={preview}
        pagination={pagination}
      />
    </table>
  </Scrollbars>;
});
