import {lazy} from "react";
import {IRoute} from "AurionCR/components/router";
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import AssistantRoundedIcon from '@material-ui/icons/AssistantRounded';
import {PERMISSIONS} from "./constants";
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

interface IRouteApp extends IRoute {
  permission?: PERMISSIONS
  routes?: undefined | IRouteApp[]
}

const routes: IRouteApp[] = [
  {
    path: '/',
    title: 'dashboard',
    icon: DashboardIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ "pages/Dashboard"))
  },
  {
    path: '/cms',
    title: 'cms',
    icon: CardMembershipIcon,
    routes: [
      {
        path: '/home',
        title: 'home',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/home")),
      },
      {
        path: '/about',
        title: 'about',
        component: lazy(() => import(/* webpackChunkName: 'HtmlPageCategories' */ "pages/HtmlPageCategories/about-us")),
      },
      {
        path: '/work',
        title: 'what-we-do',
        component: lazy(() => import(/* webpackChunkName: 'HtmlPageCategories' */ "pages/HtmlPageCategories/what-we-do")),
      },
      {
        path: '/team',
        title: 'team',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/news',
        title: 'news',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/blog',
        title: 'blog',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/open-positions',
        title: 'open-positions',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/products',
        title: 'products',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/services',
        title: 'services',
        component: lazy(() => import(/* webpackChunkName: 'HtmlPageCategories' */ "pages/HtmlPageCategories/services")),
      },
      {
        path: '/partners',
        title: 'partners',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/terms',
        title: 'terms-and-conditions',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/terms")),
      },
      {
        path: '/privacy',
        title: 'privacy-policy',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/privacy")),
      },
      {
        path: '/milestones',
        title: 'milestones',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/community',
        title: 'community',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
      {
        path: '/contact-us',
        title: 'contact-us',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/contact")),
      },
      {
        path: '/thanks',
        title: 'thank-you-page',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/thanks")),
      },
    ]
  },
  {
    path: '/clients',
    title: 'clients',
    icon: WorkOutlineIcon,
    routes: [
      {
        path: '/index',
        title: 'clients',
        component: lazy(() => import(/* webpackChunkName: 'Clients' */ "pages/Clients")),
      },
      {
        path: '/layout',
        title: 'clients-layout',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/clients")),
      },
      {
        path: '/testimonials',
        title: 'testimonials',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "pages/ZTemp")),
      },
    ]
  },
  {
    path: '/tech-ventures',
    title: 'tech-ventures',
    icon: AssistantRoundedIcon,
    routes: [
      {
        path: '/index',
        title: 'ventures',
        component: lazy(() => import(/* webpackChunkName: 'TeachVentures' */ "pages/TeachVentures")),
      },
      {
        path: '/layout',
        title: 'tech-ventures-layout',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/teach-ventures")),
      }
    ]
  },
  {
    path: '/team',
    title: 'team',
    icon: GroupWorkIcon,
    permission: PERMISSIONS.EDIT_SETTINGS,
    routes: [
      {
        path: '/index',
        title: 'team',
        component: lazy(() => import(/* webpackChunkName: 'TeamMembers' */ "pages/TeamMembers")),
      },
      {
        path: '/categories',
        title: 'team-categories',
        component: lazy(() => import(/* webpackChunkName: 'TeamMemberCategories' */ "pages/TeamMemberCategories")),
      },
      {
        path: '/layout',
        title: 'team-layout',
        component: lazy(() => import(/* webpackChunkName: 'CommonPages' */ "pages/CommonPages/team")),
      }
    ]
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: PERMISSIONS.EDIT_SETTINGS,
    routes: [
      {
        path: '/users',
        title: 'users-crm',
        permission: PERMISSIONS.EDIT_CRM_USERS,
        component: lazy(() => import(/* webpackChunkName: 'Users' */ "pages/Users")),
      },
      {
        path: '/email-templates',
        title: 'email-templates',
        component: lazy(() => import(/* webpackChunkName: 'EmailTemplates' */ "pages/EmailTemplates")),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(() => import(/* webpackChunkName: 'Permissions' */ "pages/Permissions")),
      },
      {
        path: '/site-parameters',
        title: 'site-parameters',
        component: lazy(() => import(/* webpackChunkName: 'SiteParameters' */ "pages/SiteParameters")),
      },
      {
        path: '/external-channels',
        title: 'external-channels',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(() => import(/* webpackChunkName: 'ExternalChannels' */ "pages/ExternalChannels")),
      },
      {
        path: '/labels-crm',
        title: 'labels-crm',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "pages/Labels/crm")),
      },
      {
        path: '/labels-website',
        title: 'labels-website',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "pages/Labels/web")),
      },
      {
        path: '/languages',
        title: 'languages',
        component: lazy(() => import(/* webpackChunkName: 'Languages' */ "pages/Languages")),
      },
      {
        path: '/team-categories',
        title: 'team-member-categories',
        component: lazy(() => import(/* webpackChunkName: 'TeamMemberCategories' */ "pages/TeamMemberCategories")),
      },
      {
        path: '/log',
        title: 'log',
        permission: PERMISSIONS.VIEW_LOG,
        component: lazy(() => import(/* webpackChunkName: 'Log' */ "pages/Log")),
      }
    ]
  },
  {
    path: '/leads',
    title: 'leads',
    icon: MailOutlineIcon,
    component: lazy(() => import(/* webpackChunkName: 'Leads' */ "pages/Leads")),
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ "pages/Profile")),
  },
  {
    path: '/video',
    title: 'video',
    icon: SettingsIcon,
    inMenu: false,
    private: false,
    inLayout: false,
    routes: [
      {
        path: '/create-room',
        title: 'create-room',
        inMenu: false,
        private: false,
        inLayout: false,
        component: lazy(() => import(/* webpackChunkName: 'VideoRoomCreate' */ "pages/VideoRoomCreate")),
      },
      {
        path: '/room',
        title: 'room',
        inMenu: false,
        private: false,
        inLayout: false,
        component: lazy(() => import(/* webpackChunkName: 'VideoRoom' */ "pages/VideoRoom")),
      },
    ]
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ "pages/Login"))
  }
];

export default routes;
