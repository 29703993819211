export enum PERMISSIONS {
  EDIT_BLOGS = 'isAllowToEditBlogs',
  EDIT_SETTINGS = 'isAllowToEditSetting',
  EDIT_JOBS = 'isAllowToEditJobs',
  EDIT_TECH_VENTURES = 'isAllowToEditTechVentures',
  EDIT_LABELS = 'isAllowToEditLabels',
  EDIT_PROFILE = 'isAllowToEditProfile',
  EDIT_CRM_USERS = 'isAllowToEditCRMUsers',
  EDIT_PERMISSIONS = 'isAllowToEditPermissions',
  VIEW_LOG = 'isAllowToViewLog',
  VIEW_SENSITIVE = 'isAllowToViewSensitiveInformation',
  RESET_SENSITIVE = 'isAllowToResetSensitiveInformation'
}
