import React, {FC} from 'react';
// style
import theme from "styles/theme"
import "AurionCR/styles/_main.scss"
import "styles/index.scss"
// store
import {Provider} from "react-redux";
import store from "AurionCR/store"
import storeConfig from "store/redux"
// layout
import Page404 from 'AurionCR/pages/Page404'
import Layout from 'pages/Layout'
// router
import routesConfig from 'configs/routes';
// notify
import {SnackbarProvider} from "notistack";
// l18n
import {
  I18nContextProvider,
  AuthContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
  AxiosInterceptors,
  ResponsiveProvider,
  RTLThemeProvider
} from "AurionCR/components";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

declare global {
  interface Window {
    authCookieName_: string;
    authRefreshCookieName_: string;
    authDateCookieName_: string;
  }
}
/**
 * TODO: TypeScript not apply yet!...
 * TODO: TypeScript not apply yet!...
 * TODO: TypeScript not apply yet!...
 */

const App: FC = () => {
  window.authCookieName_ = 'apo_auth_token_crm';
  window.authRefreshCookieName_ = 'apo_auth_refresh_crm';
  window.authDateCookieName_ = 'apo_auth_expires_crm';
  return <ResponsiveProvider>
    <Provider store={store(storeConfig)}>
      <AxiosInterceptors/>
      <AuthContextProvider>
        <I18nContextProvider apiLabelsFilter='labelSource.key == "crm"'>
          <RTLThemeProvider theme={theme}>
            <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
              <Notifier/>
              <LoadingOverlayProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Routes
                    layout={Layout}
                    routes={routesConfig}
                    page404={Page404}
                  />
                </MuiPickersUtilsProvider>
              </LoadingOverlayProvider>
            </SnackbarProvider>
          </RTLThemeProvider>
        </I18nContextProvider>
      </AuthContextProvider>
    </Provider>
  </ResponsiveProvider>;
};

export default App;
