import React, {memo, ReactNode} from "react";
import {IDataGridMadel} from "../model";
import Column from "./column";
import Controls from "./controls";
import Swap from "./column/templates/swap";

// const

const Body = memo((
  {
    model,
    data,
    scroll,
    onTasks,
    rowControls,
    preview,
    pagination
  }:
    {
      model: IDataGridMadel;
      data: any[];
      scroll: { x: number, y: number, '-x': number };
      onTasks: (tasks: any[]) => void;
      rowControls?: ReactNode;
      preview?: boolean
      pagination: any
    }) => {
  if (!model) return null;
  return <tbody>
  {
    data &&
    data.map((item, index) => {
        const key = item[model.store.mainField];
        return (
          <tr
            key={key}
          >
            {!preview && model.store.swap && model.store.swap.field && <Swap
              store={model.store}
              onTasks={onTasks}
              pagination={pagination}
              scroll={scroll}
              rowData={item}
              index={index}
            />}
            {model.columns.map(column =>
              <Column
                key={`${key}-${column.field}`}
                column={column}
                data={item}
                onTasks={onTasks}
                preview={preview}
              />
            )}
            {(!preview && (model.controls || rowControls)) && <Controls
              controls={model.controls}
              data={item}
              id={key}
              onTasks={onTasks}
              scroll={scroll}
              rowControls={rowControls}
            />}
          </tr>
        )
      }
    )}
  </tbody>;
});

export default memo(Body);
